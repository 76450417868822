module.exports = [{
      plugin: require('../node_modules/@willthevideoman/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"domainId":"15bfc28f-379f-457a-928a-5e971882c533","server":"https://ackee.willhall.uk","ignoreLocalhost":true,"detailed":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Will Hall's Journey Through Everything.","short_name":"Will Hall Blog","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d1949555e09d6df7c1f7e33fd65bfb0d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
